import FuseUtils from "@fuse/utils/FuseUtils";
import axios from "axios";
import recruitingServiceConfig from "./recruitingServiceConfig";

class RecruitingService extends FuseUtils.EventEmitter {
    init() {}

    //#region Recruiting requests

    fetchRecruitingRequests = (data) => {        
        return new Promise((resolve, reject) => {
          axios.post(recruitingServiceConfig.recruitingRequestsUrl, data).then((response) => {
            if (response.succeeded) {
              resolve(response.result);
            } else {
              reject(response.message);
            }
          });
        });
    };


    fetchRecruitingRequestSummary = (data) => {        
        return new Promise((resolve, reject) => {
            axios.get(recruitingServiceConfig.recruitingRequestSummaryUrl).then((response) => {
                if (response.succeeded) {
                    resolve(response.result);
                } else {
                    reject(response.message);
                }
            });
        });
    };

    fetchRecruitingRequest = (data) => {
      return new Promise((resolve, reject) => {
        axios
          .get(recruitingServiceConfig.recruitingRequestUrl + `/${data}`)
          .then((response) => {
            if (response.succeeded) {
              resolve(response.result);
            } else {
              reject(response.message);
            }
          });
      });
    };

    saveRecruitingRequest = (data) => {
      return new Promise((resolve, reject) => {
        axios.post(recruitingServiceConfig.saveRecruitingRequestUrl, data).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };

    addRecruitingRequestUrl = (data) => {
      return new Promise((resolve, reject) => {
        axios.post(recruitingServiceConfig.addRecruitingRequestUrl, data).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };

    updateProviderStatusRecruitingRequest = (data) => {
      return new Promise((resolve, reject) => {
        axios.post(recruitingServiceConfig.updateProviderStatusRecruitingRequestUrl, data).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };

    fetchRecruitingCardHeader = (data) => {
      return new Promise((resolve, reject) => {
        axios
          .get(recruitingServiceConfig.recruitingDetailCardHeaderUrl + `/${data}`)
          .then((response) => {
            if (response.succeeded) {
              resolve(response.result);
            } else {
              reject(response.message);
            }
          });
      });
    };

    fetchRecruitingCardCheckList = (data) => {
      return new Promise((resolve, reject) => {
        axios
          .get(recruitingServiceConfig.recruitingDetailCardCheckListUrl + `/${data}`)
          .then((response) => {
            if (response.succeeded) {
              resolve(response.result);
            } else {
              reject(response.message);
            }
          });
      });
    };
    fetchRecruitingCardReminder = (data) => {
      return new Promise((resolve, reject) => {
        axios
          .get(recruitingServiceConfig.recruitingDetailCardReminderUrl + `/${data}`)
          .then((response) => {
            if (response.succeeded) {
              resolve(response.result);
            } else {
              reject(response.message);
            }
          });
      });
    };
    fetchRecruitingCardDoucment = (data) => {
      return new Promise((resolve, reject) => {
        axios
          .get(recruitingServiceConfig.recruitingDetailCardDoucmentUrl + `/${data}`)
          .then((response) => {
            if (response.succeeded) {
              resolve(response.result);
            } else {
              reject(response.message);
            }
          });
      });
    };

    downloadDocument = (documentId) => {
      return new Promise((resolve, reject) => {
        axios
          .get(`${recruitingServiceConfig.downloadDocumentsUrl}/${documentId}`, {
            responseType: "blob",
            timeout: 30000,
          })
          .then((response) => {          
            if (response instanceof Blob) {
              resolve(response);
            } else {
              reject(new Error("Failed to download document."));
            }
          })
          .catch((error) => {         
            reject(new Error("Failed to download document."));
          });
      });
    };
    //#end region

    //RecruitingRequestActivityNote regoin
    saveRecruitingRequestActivityNote = (data) => {
      return new Promise((resolve, reject) => {
        axios.post(recruitingServiceConfig.saveRecruitingRequesNotetUrl, data).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };

    fetchRecruitingRequestActivityNote = (data, noteType,entityId) => {
      return new Promise((resolve, reject) => {       
        axios.get(recruitingServiceConfig.fetchRecruitingRequesNoteUrl+ `/${data}?noteTypeId=${noteType}&entityId=${entityId}`)
        .then((response) => {
          if (response.succeeded) {
            resolve(response.result);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };

    fetchRecruitingRequestActivityNoteById = (id) => {
      return new Promise((resolve, reject) => {       
        axios.get(recruitingServiceConfig.fetchRecruitingRequesNoteByIdUrl+ `/${id}`)
        .then((response) => {
          if (response.succeeded) {
            resolve(response.result);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };

    deleteRecruitingRequest = (id) => {     
      return new Promise((resolve, reject) => {
        axios
          .delete(`${recruitingServiceConfig.recruitingRequestDeleteUrl}/${id}`)
          .then((response) => {
            if (response.succeeded) {
              resolve(response.message);
            } else {
              reject(new Error("Failed to delete recruiting request."));
            }
          })
          .catch((error) => {
            reject(new Error("Failed to delete recruiting request."));
          });
      });
    };

    deleteNote = (id) => {     
      return new Promise((resolve, reject) => {
        axios
          .delete(`${recruitingServiceConfig.deleteRecruitingRequesNoteUrl}/${id}`)
          .then((response) => {
            if (response.succeeded) {
              resolve(response.message);
            } else {
              reject(new Error("Failed to delete note."));
            }
          })
          .catch((error) => {
            reject(new Error("Failed to delete note."));
          });
      });
    };

    fetcRecrutingProviderLookup = (data, searchTerm, limit) => {    
      return new Promise((resolve, reject) => {
        const url =
        recruitingServiceConfig.fetchRecruitingProviderProviderUrl + `/${data}` +
          `?searchTerm=${searchTerm ?? ""}&limit=${limit}`;
  
        axios.get(url).then((response) => {
          if (response.succeeded) {
            resolve(response.result);
          } else {
            reject(response.message);
          }
        });
      });
    };

    fetcRecrutingActivityNoteType = (searchTerm, limit) => {    
      return new Promise((resolve, reject) => {
        const url =
        recruitingServiceConfig.fetchRecruitingActivityNoteTypesUrl +
          `?searchTerm=${searchTerm ?? ""}&limit=${limit}`;
  
        axios.get(url).then((response) => {
          if (response.succeeded) {
            resolve(response.result);
          } else {
            reject(response.message);
          }
        });
      });
    };


    //#end region
    saveRecruitingRequestProviderWeeklyAvailable = (data) => {
      return new Promise((resolve, reject) => {
        axios.post(recruitingServiceConfig.saveRecruitingRequesProviderWeeklyAvailableUpdateUrl, data).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };


    saveRecruitingRequestProviderEmailPhone = (data) => {
      return new Promise((resolve, reject) => {
        axios.post(recruitingServiceConfig.saveRecruitingRequesProviderEmailPhoneUrl, data).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };
  
    //RecruitingRequestReminder regoin

  saveRecruitingRequestReminder = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(recruitingServiceConfig.saveRecruitingRequesReminderUrl, data).then((response) => {
        if (response.succeeded) {
          resolve(response);
        } else {
          reject({ message: response.message, errors: response.errors });
        }
      });
    });
  };

  deletecruitingRequestReminder = (data) => {
    return new Promise((resolve, reject) => {
      axios.delete(recruitingServiceConfig.deleteRecruitingRequesReminderUrl+ `/${data}`).then((response) => {
        if (response.succeeded) {
          resolve(response);
        } else {
          reject({ message: response.message, errors: response.errors });
        }
      });
    });
  };
    //#end region
 
  

    saveDocument = (data) => {
      return new Promise((resolve, reject) => {
        axios.post(recruitingServiceConfig.saveDocumentUrl, data).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };


    deleteDocument = (documentId) => {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${recruitingServiceConfig.deletDocumentsUrl}/${documentId}`)
          .then((response) => {
            if (response.succeeded) {
              resolve(response.message);
            } else {
              reject(new Error("Failed to delete document."));
            }
          })
          .catch((error) => {
            reject(new Error("Failed to delete document."));
          });
      });
    };


    toggleReminder = (reminderId) => {
      return new Promise((resolve, reject) => {
        axios.post(`${recruitingServiceConfig.toggleReminderUrl}/${reminderId}`).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };

    toggleChecklist = (data) => {
      return new Promise((resolve, reject) => {
        axios.post(recruitingServiceConfig.toggleChecklistUrl,data).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };

    toggleIsConfirmed = (recruitingProviderRequestId,entityId) => {
      return new Promise((resolve, reject) => {
          axios.post(`${recruitingServiceConfig.changeProviderRequestStatusUrl}/${recruitingProviderRequestId}/ToggleIsConfirmed/${entityId}`).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };
    changeProviderRequestStatus = (recruitingProviderRequestId,id) => {
      return new Promise((resolve, reject) => {
        axios.post(`${recruitingServiceConfig.changeProviderRequestStatusUrl}/${recruitingProviderRequestId}/status/${id}`).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };
    changeProviderRequestSource = (recruitingProviderRequestId,id) => {
      return new Promise((resolve, reject) => {
        axios.post(`${recruitingServiceConfig.changeProviderRequestSourceUrl}/${recruitingProviderRequestId}/source/${id}`).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };

    changeRequestStatus = (recruitingRequestId,id) => {
      return new Promise((resolve, reject) => {
        axios.post(`${recruitingServiceConfig.changeRequestStatusUrl}/${recruitingRequestId}/status/${id}`).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };


    addProviderRequest = (recruitmentRequestId,providerId) => {
      return new Promise((resolve, reject) => {
        axios.post(`${recruitingServiceConfig.addProviderRequestUrl}/${recruitmentRequestId}/providers/${providerId}`).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };


    updateRequest = (data) => {
      return new Promise((resolve, reject) => {
        axios.post(recruitingServiceConfig.saveRecruitingRequestUrl,data).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };

    deleteProvider = (recruitingProviderRequestId) => {
      return new Promise((resolve, reject) => {
        axios.post(`${recruitingServiceConfig.deleteProviderUrl}/${recruitingProviderRequestId}`).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };

    createRecruitingProviderRequest = (data) => {
      return new Promise((resolve, reject) => {
        axios.post(recruitingServiceConfig.saveRecruitingProviderRequestUrl,data).then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject({ message: response.message, errors: response.errors });
          }
        });
      });
    };

    // fetching Reminders by User Id
fetchActiveRemindersByUser = () => {        
  return new Promise((resolve, reject) => {
      axios.get(recruitingServiceConfig.fetchActiveRemindersByUserUrl).then((response) => {
          if (response.succeeded) {
              resolve(response.result);
          } else {
              reject(response.message);
          }
      });
  });
};

}





const instance = new RecruitingService();

export default instance;