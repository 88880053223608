import { lazy } from "react";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";

const UserPermissionPage = lazy(() => import("./pages/UserPermissionPage"));


const UserPermissionConfig = {
  settings: {
    layout: {},
  },
  auth: settingsConfig.defaultAuth,//['admin']
  routes: [
    {
      path: "admin/user-permission",
      element: <UserPermissionPage />,
    },  
  ],
};

export default UserPermissionConfig;
