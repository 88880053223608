import { lazy } from "react";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";

const AccountPage = lazy(() => import("./pages/my-account/AccountPage"));

const AccountConfig = {
  settings: {
    layout: {},
  },
  //auth: settingsConfig.defaultAuth, //['admin']
  routes: [
    {
      path: "account/overview",
      element: <AccountPage />,
    },
  ],
};

export default AccountConfig;
