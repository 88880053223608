import { lazy } from "react";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";

const OverviewPage = lazy(() => import("./pages/overview/OverviewPage"));
const DetailsPage = lazy(() => import("./pages/details/DetailsPage"));

const DashboardConfig = {
  settings: {
    layout: {},
  },
  auth: settingsConfig.defaultAuth,//['admin']
  routes: [
    {
      path: "dashboard/overview",
      element: <OverviewPage />,
    },
    {
      path: "dashboard/details",
      element: <DetailsPage />,
    },    

  ],
};

export default DashboardConfig;
