import FuseUtils from "@fuse/utils/FuseUtils";
import axios from "axios";
import userMngServiceConfig from "./userMngServiceConfig";

class UserMngService extends FuseUtils.EventEmitter {
  init() { }

  //#region Users

  fetchUsers = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(userMngServiceConfig.usersUrl, data).then((response) => {
        if (response.succeeded) {
          resolve(response.result);
        } 
        else {
          reject(response.message);
          // if (!response.isValid) {
          //   navigate('/404');
          // }
          // else {
          //   reject(response.message);
          // }

        }
      });
    });
  };

  fetchUserProviders = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(userMngServiceConfig.userProvidersUrl, data).then((response) => {
        if (response.succeeded) {
          resolve(response.result);
        } 
        else {
          reject(response.message);         
        }
      });
    });
  };


  fetchUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.get(userMngServiceConfig.userUrl + `/${data}`).then((response) => {
        if (response.succeeded) {
          resolve(response.result);
        } else {
          reject(response.message);
        }
      });
    });
  };

  saveUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(userMngServiceConfig.saveUserUrl, data).then((response) => {
        if (response.succeeded) {
          resolve(response.message);
        } else {
          reject({ message: response.message, errors: response.errors });
        }
      });
    });
  };

  isUserNameUnique = (userId, username) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          userMngServiceConfig.isUserNameUniqueUrl +
          `?username=${username}&userId=${userId}`
        )
        .then((response) => {
          if (response.succeeded) {
            resolve(response.result);
          } else {
            reject(response.message);
          }
        });
    });
  };

  //#endregion

  //#region Lookups

  fetchUserLookups = (searchTerm, limit) => {
    return new Promise((resolve, reject) => {
      const url =
        userMngServiceConfig.userLookupsUrl +
        `?searchTerm=${searchTerm ?? ""}&limit=${limit}`;

      axios.get(url).then((response) => {
        if (response.succeeded) {
          resolve(response.result);
        } else {
          reject(response.message);
        }
      });
    });
  };

  fetchRoleLookups = (searchTerm, limit) => {
    return new Promise((resolve, reject) => {
      const url =
        userMngServiceConfig.roleLookupsUrl +
        `?searchTerm=${searchTerm ?? ""}&limit=${limit}`;

      axios.get(url).then((response) => {
        if (response.succeeded) {
          resolve(response.result);
        } else {
          reject(response.message);
        }
      });
    });
  };

  fetchPermissionByRoleId = (roleId) => {
    return new Promise((resolve, reject) => {
      const url =
        userMngServiceConfig.permissionByRoleIdUrl +
        `?roleId=${roleId}`;

      axios.get(url).then((response) => {
        if (response.succeeded) {
          resolve(response.result);
        } else {
          reject(response.message);
        }
      });
    });
  };
  togglePermissionStatus = (permissionId, roleId) => {
    return new Promise((resolve, reject) => {
      axios.post(`${userMngServiceConfig.togglePermissionStatusIdUrl}/${permissionId}/TogglePermissionStatus/${roleId}`).then((response) => {
        if (response.succeeded) {
          resolve(response);
        } else {
          reject({ message: response.message, errors: response.errors });
        }
      });
    });
  };


  fetchStatusLookups = (searchTerm, limit) => {
    return new Promise((resolve, reject) => {
      const url =
        userMngServiceConfig.statusLookupsUrl +
        `?searchTerm=${searchTerm ?? ""}&limit=${limit}`;

      axios.get(url).then((response) => {
        if (response.succeeded) {
          resolve(response.result);
        } else {
          reject(response.message);
        }
      });
    });
  };

  resetPassword = (userId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${userMngServiceConfig.resetpasswordUrl}?UserId=${userId}`)
        .then((response) => {
          if (response.succeeded) {
            resolve(response.message);
          } else {
            reject(new Error("Failed to reset password."));
          }
        })
        .catch((error) => {
          reject(new Error("Failed to reset password."));
        });
    });
  };

  deleteUser = (userId) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${userMngServiceConfig.userUrl}/${userId}`)
        .then((response) => {
          if (response.succeeded) {
            resolve(response.message);
          } else {
            reject(new Error("Failed to delete user."));
          }
        })
        .catch((error) => {
          reject(new Error("Failed to delete user."));
        });
    });
  };

  unLockUser = (userId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${userMngServiceConfig.unlockUserUrl}?UserId=${userId}`)
        .then((response) => {
          if (response.succeeded) {
            resolve(response.message);
          } else {
            reject(new Error("Failed to unlock user."));
          }
        })
        .catch((error) => {
          reject(new Error("Failed to unlock user."));
        });
    });
  };

  fetchVersionNumber = () => {
    return new Promise((resolve, reject) => {
      axios.get(userMngServiceConfig.versionNumberUrl).then((response) => {
        if (response.succeeded) {
          resolve(response.result);
        } else {
          reject(response.message);
        }
      });
    });
  };

  changePassword = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(userMngServiceConfig.changePasswordUrl, data)
        .then((response) => {
          if (response.succeeded) {
            resolve(response.result);
          } else {
            reject(response);
          }
        });
    });
  };

  mappedProviderByUserId = (entityId,userId) => {
    return new Promise((resolve, reject) => {
      axios
      .post(`${userMngServiceConfig.mappedProviderByUserIdUrl}/${entityId}/MappedProviderByUser/${userId}`)    
        .then((response) => {
          if (response.succeeded) {
            resolve(response);
          } else {
            reject(response);
          }
        });
    });
  };

  //#endregion
}

const instance = new UserMngService();

export default instance;
