const jwtServiceConfig = {
  signIn: 'api/auth/accesstoken',
  signUp: 'api/auth/signup',
  accessToken: 'api/auth/signinwithtoken',
  updateUser: 'api/auth/update',
  forgotPassword: 'api/auth/ForgotPassword',
  resetPassword: 'api/auth/ResetPassword',
  confirmEmail: 'api/auth/ConfirmEmail',
  getUserRoleUrl: 'api/auth/IsCustomerOnly',
  impersonateUserUrl: '/api/Admin/UserManagement/Impersonate',
  backFromImpersonationUserUrl: '/api/Admin/UserManagement/BackFromImpersonation'
};

export default jwtServiceConfig;
