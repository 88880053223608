import i18next from "i18next";
import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";
import authRoles from "../auth/authRoles";
//import customerProviders from "app/shared-components/CustomerProviders";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

// const provider  = CustomerProviders();

const getUserProvider = (state) => state.user.provider;

const navigationConfig = [
  {
    id: "landingPortal",
    icon: "heroicons-outline:identification",
    title: "Home",
    type: "item",
    url: "landingPortal/Overview/",
    end: true,
    claimValue: ["Home (visible)"],
    claimType: ["Admin"],
  },  
  {
    id: "dashboard",
    icon: "heroicons-outline:identification",
    title: "Dashboard",
    icon: "heroicons-outline:chart-bar",
    
    type: "item",
    url: "dashboard/Overview/",
    end: true,
    claimValue: ["Dashbaord - Billing","Dashbaord - Recruiting","Dashbaord - User Activity"],
    claimType: ["Admin"],
  },  
  {
    
    id: "CRM",
    title: "Providers",
    type: "collapse",
    icon: "heroicons-outline:user",
    translate: "Providers",
    claimValue: ["Provider Listing (visible)","Provider Import (visible)"],
    claimType: ["Providers"],
    isCustomer:true,
    
    //auth:[...authRoles.billing,...authRoles.recruiting ,...authRoles.customer],...authRoles.admin,
    children: [
      {
        id: "Customers",
        title: "Listing",
        type: "item",
        url: "crm/customers",
        end: true,
        claimValue: ["Provider Listing (visible)"],
        claimType: ["Providers"],
      },
      {
        id: "ProvidersImport",
        title: "Providers Import",
        type: "item",
        url: "crm/customersImport",
        end: true,
        claimValue: ["Provider Import (visible)"],
        claimType: ["Providers"],
       
      },
      {
        id: "ExpirationReview",
        title: "Expiration Review",
        type: "item",
        url: "crm/expirationReview",
        end: true,
        claimValue: ["Provider Document Expiration (visible)"],
        claimType: ["Providers"],
      },      
    ],
  },
  {
    id: "recruiting",
    title: "recruiting",
    type: "collapse",
    icon: "heroicons-outline:user-group",
    translate: "Recruiting",
    claimValue: ["Recruiting (visible)"],
    claimType: ["Recruiting"],
   // auth: authRoles.recruiting,
    children: [
      {
        id: "blank",
        title: "Overview",
        type: "item",
        url: "recruiting/overview",
        end: true,
        claimValue: ["Recruiting (visible)"],
        claimType: ["Recruiting"],
      },
    ],
  },
  {
    id: "Billing",
    title: "Billing",
    type: "collapse",
    icon: "heroicons-outline:credit-card",
    translate: "Billing",
    claimValue: ["Dashboard (visible)","Import (visible)","Overview (visible)","QTC Metric Import (visible)","QTC Metric Overview (visible)"],
    claimType: ["Billing"],
    //auth: authRoles.billing,
    children: [
      {
        id: "Dashboard",
        title: "Dashboard",
        type: "item",
        url: "billing/dashboard",
        end: true,
        claimValue: ["Dashboard (visible)"],
        claimType: ["Billing"],
      },
      {
        id: "Import",
        title: "Billing Import",
        type: "item",
        url: "billing/import",
        end: true,
        claimValue: ["Import (visible)"],
        claimType: ["Billing"],
      },
      {
        id: "Overview",
        title: "Billing Overview",
        type: "item",
        url: "billing/overview",
        end: true,
        claimValue: ["Overview (visible)"],
        claimType: ["Billing"],
      },
      {
        id: "qtcImport",
        title: "QTC Metrics Import",
        type: "item",
        url: "billing/qtc",
        end: true,
        claimValue: ["QTC Metric Import (visible)"],
        claimType: ["Billing"],
      },
      {
        id: "qtcOverview",
        title: "QTC Metrics Overview",
        type: "item",
        url: "billing/qtcOverview",
        end: true,
        claimValue: ["QTC Metric Overview (visible)"],
        claimType: ["Billing"],
      },
    ],
  },
  {
    id: "Admin",
    title: "Admin",
    type: "collapse",
    icon: "heroicons-outline:cog",
    translate: "Admin",
    claimValue: ["User Mgmt (visible)","Lookup Mgmt (visible)","Partners (visible)","Home Edit (visible)"],
    claimType: ["Admin"],
   // auth: authRoles.admin,
    children: [
      {
        id: "UserManagement",
        title: "Users Management",
        type: "item",
        url: "admin/users",
        end: true,
        claimValue: ["User Mgmt (visible)"],
        claimType: ["Admin"],
      },
      {
        id: "lookup-management",
        title: "Lookup Management",
        type: "item",
        url: "lookup-management/overview/",
        end: true,
        claimValue: ["Lookup Mgmt (visible)"],
        claimType: ["Admin"],
      },      
      {
        id: "partners",
        title: "Partners",
        type: "item",
        url: "billing/partners",
        end: true,
        claimValue: ["Partners (visible)"],
        claimType: ["Admin"],
      },
      {
        id: "landingPortalEdit",
        title: "Home Edit",
        type: "item",
        url: "landingPortal/details",
        end: true,
        claimValue: ["Home Edit (visible)"],
        claimType: ["Admin"],
      }, 
      {
        id: "userPermissions",
        title: "User Permissions",
        type: "item",
        url: "admin/user-permission",
        end: true,
      
      }, 
    ],
  },
  {
    id: "customerPortal",
    title: "customerPortal",
    type: "collapse",
    icon: "heroicons-outline:identification",
    translate: "Portal",
    auth: authRoles.customer,
    children: [],
  },
 
  {
    id: "accountPortal",
    title: "customerPortal",
    type: "collapse",
    icon: "heroicons-outline:cog",
    translate: "Settings",
    claimValue: ["Setting (visible)"],
    claimType: ["Admin"],
    children: [
      {
        id: "MyAccount",
        title: "My Account",
        type: "item",
        url: "account/overview",
        end: true,
        claimValue: ["Setting (visible)"],
        claimType: ["Admin"],
      },     
    ],
  },
];

export default navigationConfig;
