import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import recruitingServices from '../../main/apps/recruiting/services/recruitingServices';
import moment from 'moment';
import { useNavigate } from "react-router-dom";

import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

function ReminderNotification(props) {

  const navigate = useNavigate();

  const [remindersMenu, setRemindersMenu] = useState(null);
  const [dueRemindersCount, setDueRemindersCount] = useState(0);
  const [remindersByUserData, setRemindersByUserData] = useState([]);

  const remindersMenuClick = (event) => {
    setRemindersMenu(event.currentTarget);
  };

  const remindersMenuClose = () => {
    setRemindersMenu(null);
  };

  useEffect(() => {
    const fetchActiveRemindersByUsers = async () => {
      try {
        let reminder = await recruitingServices.fetchActiveRemindersByUser();
        setRemindersByUserData(reminder);
      } catch (e) {
        // Handle error here
      }
    };
    fetchActiveRemindersByUsers();
  }, []);


  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate()).getTime();
  }

  const isReminderDue = (reminder) => {
    const currentDate = formatDate(new Date());
    const reminderDate = formatDate(reminder.activated);
    return reminderDate >= currentDate;
  };

  useEffect(() => {

    if (remindersByUserData) {
      const currentDate = formatDate(new Date());
      const dueReminders = remindersByUserData.filter(reminder => {
        const reminderDate = formatDate(reminder.activated);
        return reminderDate >= currentDate;
      });
      setDueRemindersCount(dueReminders.length);
    }

  }, [remindersByUserData]);
  return (
    <>
      {
        remindersByUserData && remindersByUserData.length > 0 && (
          <>
            <Button
              className="min-h-40 min-w-40 px-0 md:px-8 py-0 md:py-6"
              onClick={remindersMenuClick}
              color="inherit"
            >
              <div style={{ position: 'relative', marginRight: 20 }}>
                <NotificationsNoneOutlinedIcon fontSize="large" />
                {dueRemindersCount > 0 && (
                  <span className="notificationNumber">
                    {dueRemindersCount}
                  </span>
                )}
              </div>
            </Button>

            <Popover
              open={Boolean(remindersMenu)}
              anchorEl={remindersMenu}
              onClose={remindersMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              classes={{
                paper: "py-8",
              }}
              PaperProps={{
                style: {
                  minWidth: '200px',
                  maxWidth: '200px'
                }
              }}
            >
             {remindersByUserData && (remindersByUserData.map(reminder => (
                <MenuItem key={reminder.id}
                          onClick={() => {
                            navigate(`/recruiting/details/${reminder.recrutingRequestId}?recruitingProviderRequestId=${reminder.recruitingProviderRequestId}`);
                          }}
                          style={{ position: 'relative' }}
                >
                  <div className="reminderContainer">
                    <div className="reminderDate">({moment(reminder.activated).format('DD MMM YYYY')})</div>
                    <div className="reminderText">{reminder.name}</div>
                    {isReminderDue(reminder) && (
                      <span style={{
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize: '1.5em',
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)'
                      }}>
                        !
                      </span>
                    )}
                  </div>
                </MenuItem>
              )))}
            </Popover>
          </>
        )
      }
    </>
  );
}

export default ReminderNotification;
