import { closeDialog } from "app/store/fuse/dialogSlice";
import { useDispatch, useSelector } from "react-redux";
import AnnouncementIcon from "@mui/icons-material/AnnouncementOutlined";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";

function ConfirmationDialog({ title, message, onAccept }) {
  const dispatch = useDispatch();

  return (
    <>
      <DialogTitle>
        <AnnouncementIcon  /> {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(closeDialog())} color="primary">
          Cancel
        </Button>
        <Button
          inputMode="outlined"
          onClick={() => {
            onAccept();
            dispatch(closeDialog());
          }}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </>
  );
}

export default ConfirmationDialog;
