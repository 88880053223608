import { motion } from "framer-motion";
import withReducer from "app/store/withReducer";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import reducer from "../../store";

// import HeaderPage from "./HeaderPage";
// import ContactsPage from "./ContactsPage";
// import ShipTosPage from "./ShipTosPage";
// import AddressesPage from "./AddressesPage";
// import Providers from "./Providers";
// import DocumentsPage from "./DocumentsPage";
// import NotesPage from "./NotesPage";
// import BillingTabPage from "./BillingTabPage";


import { useState } from "react";
// import ContainerWrapper from "./ContainerWrapper";
// import * as React from 'react';
// import PropTypes from 'prop-types';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';


function CustomerPage() {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [displayShipto, setDisplayShipto] = useState('none');
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

// TABS
// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

// const [value, setValue] = React.useState(0);

// const handleChange = (event, newValue) => {
//   setValue(newValue);
// };

// // TABS END

//   return (
//     <>
//       <HeaderPage />
//         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//           <Tabs value={value} onChange={handleChange} textColor="secondary"  indicatorColor="secondary" className="px-36 mt-24">
//             <Tab label="General" {...a11yProps(0)} />
//             <Tab label="Billing" {...a11yProps(1)} />
//           </Tabs>
//         </Box>
//         <TabPanel value={value} index={0}>
//           <motion.div
//             className="grid grid-cols-1 gap-32 w-full"
//             variants={container}
//             initial="hidden"
//             animate="show"
//           >
//             {/* <Providers/> */}
//             <AddressesPage/>
//             <ContainerWrapper>
//               <ShipTosPage />
//             </ContainerWrapper>
//             <ContactsPage/>
//             <DocumentsPage/>
//             <NotesPage />       
//           </motion.div>
//         </TabPanel>
//         <TabPanel value={value} index={1}>
//           <motion.div
//             variants={container}
//             initial="hidden"
//             animate="show"
//           >
//             <BillingTabPage/>
//           </motion.div>
//         </TabPanel>
//     </>
//   );
}

export default withReducer("customerPortal", reducer)(CustomerPage);
