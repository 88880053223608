import { lazy } from "react";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";

const UsersPage = lazy(() => import("./pages/users/UsersPage"));
const HomePage = lazy(() => import("./pages/users/HomePage"));

const UsersMngConfig = {
  settings: {
    layout: {},
  },
  //auth: settingsConfig.defaultAuth,//['admin']
  routes: [
    {
      path: "admin/users",
      element: <UsersPage />,
    },
    {
      path: "admin/home",
      element: <HomePage />,
    },    
    {
      path: "admin",
      element: <Navigate to="users" />,
    },
  ],
};

export default UsersMngConfig;
