import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import * as yup from "yup";
import _ from "@lodash";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import jwtService from "../../../auth/services/jwtService";
import LoadingAnimation from "../../../shared-components/LoadingAnimation";
import { IconButton } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOutlinedOffIcon from '@mui/icons-material/VisibilityOffOutlined';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().required("You must enter a email"),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(4, "Password is too short - must be at least 4 chars."),
});

const defaultValues = {
  email: "",
  password: "",
  remember: true,
};

function SignInPage() {
  const { control, formState, handleSubmit, setError, setValue, reset } =
    useForm({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema),
    });

  const [responseMessage, setResponseMessage] = useState({
    isError: false,
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const { isValid, dirtyFields, errors } = formState;

  const [showPassword, setShowPassword] = useState(false); 
  const handleClickShowPassword = () => setShowPassword((show) => !show); 
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setValue("email", "", { shouldDirty: false, shouldValidate: false });
    setValue("password", "", { shouldDirty: false, shouldValidate: false });
  }, [setValue]);

  function onSubmit({ email, password }) {
    setResponseMessage({ isError: false, message: "" });
    setIsLoading(true);

    jwtService
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        // No need to do anything, user data will be set at app/auth/AuthContext
      })
      .catch((errorResult) => {
        if (errorResult.message) {
          setResponseMessage({ isError: true, message: errorResult.message });
        }
      })
      .finally(()=>{
        setIsLoading(false);
      });
  }

  let alertControl = "";

  if (responseMessage.message !== "") {
    alertControl = responseMessage.isError ? (
      <Alert className="w-full mt-4" severity="error">
        {responseMessage.message}
      </Alert>
    ) : (
      <Alert className="w-full mt-4" severity="info">
        {responseMessage.message}
      </Alert>
    );
  }

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      {isLoading ? <LoadingAnimation /> : ""}
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          {/* <img className="h-64" src="assets/images/logo/monterey-original-logo.png" alt="" /> */}

          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Sign in
          </Typography>
          {/* <div className="flex items-baseline mt-2 font-medium">
            <Typography>Don't have an account?</Typography>
            <Link className="ml-4" to="/sign-up">
              Sign up
            </Link>
          </div> */}

          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        component="label"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOutlinedOffIcon size="small" /> : <VisibilityOutlinedIcon size="small" />}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />

            {alertControl}

            <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
              <Controller
                name="remember"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormControlLabel
                      label="Remember me"
                      control={<Checkbox size="small" {...field} />}
                    />
                  </FormControl>
                )}
              />

              <Link className="text-md font-medium" to="/forgot-password">
                Forgot password?
              </Link>
            </div>

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-16 mb-16"
              aria-label="Sign in"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              Sign in
            </Button>
            <p style={{fontSize:"75%", margin:"0 auto", textAlign:"center"}}>By clicking "Sign In" you are agreeing to the Terms of Use</p>
            <div className="flex mt-48" style={{justifyContent: "space-evenly"}}>
              <a href="/terms">Terms of Use</a>
              <a href="https://www.mcix.com/privacy-policy/" target="_" rel="noopener noreferrer" >Privacy Policy</a>
            </div>
            
            {/* <div className="flex items-center mt-32">
              <div className="flex-auto mt-px border-t" />
              <Typography className="mx-8" color="text.secondary">
                Or continue with
              </Typography>
              <div className="flex-auto mt-px border-t" />
            </div>

            <div className="flex items-center mt-32 space-x-16">
              <Button variant="outlined" className="flex-auto">
                <FuseSvgIcon size={20} color="action">
                  feather:facebook
                </FuseSvgIcon>
              </Button>
              <Button variant="outlined" className="flex-auto">
                <FuseSvgIcon size={20} color="action">
                  feather:twitter
                </FuseSvgIcon>
              </Button>
              <Button variant="outlined" className="flex-auto">
                <FuseSvgIcon size={20} color="action">
                  feather:github
                </FuseSvgIcon>
              </Button>
            </div> */}
          </form>
        </div>
      </Paper>

      <Box
        className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
        sx={{ backgroundColor: "primary.main" }}
      >
        <svg
          className="absolute inset-0 pointer-events-none"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: "primary.light" }}
            className="opacity-20"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
 
          </Box>
        </svg>
        <Box
          component="svg"
          className="absolute -top-64 -right-64 opacity-20"
          sx={{ color: "primary.light" }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
 
        </Box>

        <div className="z-10 relative w-full max-w-2xl">
          <div className="text-7xl font-bold leading-none text-gray-100 text-center">
            <img src="assets/images/logo/monterey-logoWhite.png" alt="" style={{margin: "0 auto"}} />
          </div>
          <div className="mt-24 text-lg tracking-tight leading-6 text-gray-400 text-center">
            For over 25 years Monterey has been dedicated to delivering innovative acquisition and medical solutions without compromising quality.
          </div>
          {/* <div className="flex items-center mt-32">
            <AvatarGroup
              sx={{
                "& .MuiAvatar-root": {
                  borderColor: "primary.main",
                },
              }}
            >
              <Avatar src="assets/images/avatars/female-18.jpg" />
              <Avatar src="assets/images/avatars/female-11.jpg" />
              <Avatar src="assets/images/avatars/male-09.jpg" />
              <Avatar src="assets/images/avatars/male-16.jpg" />
            </AvatarGroup>

            <div className="ml-16 font-medium tracking-tight text-gray-400">
              More than 17k people joined us, it's your turn
            </div>
          </div> */}
        </div>
      </Box>
    </div>
  );
}

export default SignInPage;
