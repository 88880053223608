import { lazy } from "react";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";

const OverviewPage = lazy(() => import("./pages/overview/OverviewPage"));


const LookupConfig = {
  settings: {
    layout: {},
  },
//  auth: settingsConfig.defaultAuth,//['admin']
  routes: [
    {
      path: "lookup-management/overview",
      element: <OverviewPage />,
    },

  ],
};

export default LookupConfig;
