import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import SignInConfig from "../main/auth/sign-in/SignInConfig";
import SignUpConfig from "../main/auth/sign-up/SignUpConfig";
import SignOutConfig from "../main/auth/sign-out/SignOutConfig";
import Error404Page from "../main/404/Error404Page";
import UsersMngConfig from "../main/apps/admin/users-management/UsersMngConfig";
import UserPermissionConfig from "../main/apps/admin/user-permissions/UserPermissionConfig";
import TermsPageConfig from "../main/auth/terms/TermsPageConfig";
import ForgotPasswordPageConfig from "../main/auth/forgot-password/ForgotPasswordPageConfig";
import ResetPasswordPagesConfig from "../main/auth/reset-password/ResetPasswordPagesConfig";
import ConfirmEmailConfig from "../main/auth/confirm-email/ConfirmEmailConfig";
import CRMConfig from "../main/apps/crm/customers/CRMConfig";
import BillingConfig from "../main/apps/billing/BillingConfig";
import BlankConfig from "../main/apps/blank/BlankConfig";
import DashboardConfig from "../main/apps/dashboard/DashboardConfig";
import LandingPortalConfig from "../main/apps/landingPortal/landingPortalConfig";
import RecruitingConfig from "../main/apps/recruiting/RecruitingConfig";
import CustomerPortalConfig from "../main/apps/customerPortal/CustomerPortalConfig";
import AccountConfig from "../main/apps/settings/AccountConfig";
import LookupConfig from "../main/apps/lookup-management/LookupConfig";
import { authRoles } from "../auth";


const routeConfigs = [
  UsersMngConfig,
  SignOutConfig,
  SignInConfig,
  SignUpConfig,
  ForgotPasswordPageConfig,
  TermsPageConfig,
  ResetPasswordPagesConfig,
  ConfirmEmailConfig,
  CRMConfig,
  BillingConfig,
  BlankConfig,
  DashboardConfig,
  LandingPortalConfig,
  RecruitingConfig,
  CustomerPortalConfig,
  AccountConfig,
  LookupConfig,
  UserPermissionConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "/",
    element: <Navigate to="landingPortal/Overview" />,
    auth: authRoles.admin,
  },
  {
    path: "/recruiting/overview",
    element: <Navigate to="recruiting/overview" />,
    auth: authRoles.recruiting,
  },
  {
    path: "/billing/dashboard",
    element: <Navigate to="billing/dashboard" />,
    auth: authRoles.billing,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "/terms",
    element: <Navigate to="terms" />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "*",
    element: <Navigate to="404" />,
  },
];

export default routes;
