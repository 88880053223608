import React from "react";
import FuseLoading from "@fuse/core/FuseLoading";

const LoadingAnimation = ({ isFixed }) => {
  const style = {
    position: isFixed ? "fixed" : "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999,
  };

  return (
    <div style={style}>
      <div className="loading">
        <FuseLoading />
      </div>
    </div>
  );
};

export default LoadingAnimation;