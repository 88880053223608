const userMngServiceConfig = {
    userUrl: '/api/Admin/UserManagement/Users',
    usersUrl: '/api/Admin/UserManagement/Users',
    userProvidersUrl: '/api/Admin/UserManagement/Providers',
    saveUserUrl: '/api/Admin/UserManagement/SaveUser',
    isUserNameUniqueUrl: '/api/Admin/UserManagement/IsUserNameUnique',
    userLookupsUrl: '/api/Admin/Lookup/Users',
    roleLookupsUrl: '/api/Admin/Lookup/Roles',
    statusLookupsUrl: '/api/Admin/Lookup/Statuses',
    resetpasswordUrl: '/api/Admin/UserManagement/ResetPassword',
    unlockUserUrl: '/api/Admin/UserManagement/UnLockUser', 
    versionNumberUrl: '/api/Recruitment/Version',
    changePasswordUrl: "/api/Auth/ChangePassword",
    permissionByRoleIdUrl: '/api/Admin/Permission/PermissionByRoleId',
    mappedProviderByUserIdUrl:'/api/Admin/UserManagement',


    togglePermissionStatusIdUrl: '/api/Admin/Permission',
};

export default userMngServiceConfig;
  