const recruitingServiceConfig = {

  //#region recruitingRequests
  recruitingRequestsUrl: "/api/Recruitment/RecruitmentRequest",
  recruitingRequestUrl: "/api/Recruitment/RecruitmentRequest",
  recruitingRequestSummaryUrl: "/api/Recruitment/RecruitmentRequest/summary",
  saveRecruitingRequestUrl: "/api/Recruitment/RecruitmentRequest/save",
  addRecruitingRequestUrl: "/api/Recruitment/RecruitmentRequest/add",
  updateProviderStatusRecruitingRequestUrl: "/api/Recruitment/RecruitingProviderRequest/update-provider/statuses",
  recruitingRequestDeleteUrl: "/api/Recruitment/RecruitmentRequest",
  //endcontacts

  //#region Providers
  recruitingDetailCardHeaderUrl:"/api/Recruitment/RecruitingProviderRequest",
  recruitingDetailCardCheckListUrl:"/api/Recruitment/RecruitingProviderRequest/checkitems",
  recruitingDetailCardReminderUrl:"/api/Recruitment/RecruitingProviderRequest/reminders",
  recruitingDetailCardDoucmentUrl:"/api/Recruitment/RecruitingProviderRequest/documents",
  //downloadDocumentsUrl: "api/Recruitment/RecruitingProviderRequest/download",
  downloadDocumentsUrl: "api/Recruitment/RecruitingProviderRequestDocument/documents",
  addProviderRequestUrl:"/api/Recruitment/RecruitingProviderRequest",
  deleteProviderUrl:"/api/Recruitment/RecruitingProviderRequest/deleteprovider",

  
  //regionEnd


  //#region recruiting activity nte
  saveRecruitingRequesNotetUrl: "/api/Recruitment/RecruitingProviderRequestNote/save",
  fetchRecruitingRequesNoteUrl: "/api/Recruitment/RecruitingProviderRequestNote/all/recruiting-request",
  fetchRecruitingRequesNoteByIdUrl: "/api/Recruitment/RecruitingProviderRequestNote",
  deleteRecruitingRequesNoteUrl: "/api/Recruitment/RecruitingProviderRequestNote",
  fetchRecruitingProviderProviderUrl:"/api/CRM/Lookup/recruiting-providers",
  fetchRecruitingActivityNoteTypesUrl:"/api/CRM/Lookup/recruiting-providers",
  //end region
  saveRecruitingRequesProviderEmailPhoneUrl: "/api/Recruitment/RecruitingProviderRequest/save-email-phone",
  saveRecruitingRequesProviderWeeklyAvailableUpdateUrl: "/api/Recruitment/RecruitingProviderRequest/save-weekly-available",
  //region Recruiting Provider Request Reminder
  saveRecruitingRequesReminderUrl: "/api/Recruitment/RecruitingProviderRequestReminder/save",
  deleteRecruitingRequesReminderUrl: "/api/Recruitment/RecruitingProviderRequestReminder",
  fetchActiveRemindersByUserUrl:"/api/Recruitment/RecruitingProviderRequestReminder/GetActiveRemindersByUser",
  
  //end regionW


  saveDocumentUrl: "/api/Recruitment/RecruitingProviderRequestDocument/documents",
  deletDocumentsUrl: "/api/Recruitment/RecruitingProviderRequestDocument/documents",

  toggleReminderUrl:"/api/Recruitment/RecruitingProviderRequestReminder/toggle",

  toggleChecklistUrl:"/api/Recruitment/RecruitingProviderRequestCheckItem",  
  toggleIsConfirmedUrl:"/api/Recruitment/RecruitingProviderRequest",

  changeProviderRequestStatusUrl:"/api/Recruitment/RecruitingProviderRequest",
  changeProviderRequestSourceUrl:"/api/Recruitment/RecruitingProviderRequest",
  changeRequestStatusUrl:"/api/Recruitment/RecruitmentRequest",
  saveRecruitingProviderRequestUrl:"/api/Recruitment/RecruitingProviderRequest/addprovider",
}

export default recruitingServiceConfig;