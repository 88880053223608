/* eslint-disable prettier/prettier */
import FuseUtils from "@fuse/utils/FuseUtils";
import axios from "axios";
import jwtDecode from "jwt-decode";
import jwtServiceConfig from "./jwtServiceConfig";

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response &&  // Check if err.response exists before using it
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };
  

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.succeeded) {
          resolve(response.message);
        } else {
          reject(response.errors);
        }
      });
    });
  };

  _getUserModel = () => {
    const userModel = {
      // "uuid": "XgbuVEXBU6gtSKdbTYR1Zbbby1i3",
      // from: "custom-db",
      // role: "admin",
      data: {
        settings: {
          layout: {},
          theme: {},
        },
        shortcuts: ["apps.calendar", "apps.mailbox", "apps.contacts"],
      },
    };

    return userModel;
  };

  signInWithEmailAndPassword = (email, password) => {
    const userModel = this._getUserModel();
    const { data } = userModel;

    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signIn, { username: email, password })
        .then((response) => {
          if (response.succeeded) {
            response.result.roles.push("guest");           
            const roles = response.result.roles.map((x) => x.toLowerCase());
            this.setSession(response.result.authData.token);
            const user = {
              ...userModel,
              data: { ...response.result.user, data },
              role: roles,
              permissions:response.result.userPermissions,
              provider: response.result.mappedEntities,
            };            
            resolve(user);
            this.emit("onLogin", user);
          } else {
            reject(new Error(response.message));
          }
        });
    });
  };

  signInWithToken = () => {
    const userModel = this._getUserModel();
    const { data } = userModel;

    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.accessToken, {
          accessToken: this.getAccessToken(),
        })
        .then((response) => {
          if (response.succeeded) {
            this.setSession(response.result.authData.token);
            response.result.roles.push("guest");
            const roles = response.result.roles.map((x) => x.toLowerCase());
            const user = {
              ...userModel,
              data: { ...response.result.user, data },
              role: roles,
              provider: response.result.mappedEntities,
              permissions:response.result.userPermissions,
              isImpersonated: localStorage.getItem("parentUserEmail") ? true : false
            };           
            resolve(user);
          } else {
            this.logout();
            reject(new Error("Failed to login with token."));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error("Failed to login with token."));
        });
    });
  };

  forgotPassword = (email) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.forgotPassword, {
          email,
        })
        .then((response) => {
          if (response.succeeded) {
            resolve(response.message);
          } else {
            if (response?.message == null) {
              reject(new Error("Failed to reset password."));
            } else {
              reject(new Error(response.message));
            }           
          }
        })
        .catch((error) => {
          reject(new Error("Failed to reset password."));
        });
    });
  };

  resetPassword = (emailAddress, password, passwordConfirm, token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.resetPassword, {
          password,
          passwordConfirm,
          token,
          emailAddress,
        })
        .then((response) => {
          if (response.succeeded) {
            resolve(response.message);
          } else {
            reject(
              
              new Error(
                response.message === undefined
                ? "Failed to reset password."
                : response.message
            )
            );
          }
        })
        .catch((error) => {
          reject(new Error("Failed to reset password."));
        });
    });
  };

  confirmEmail = (emailAddress, token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.confirmEmail, {
          emailAddress,
          token,
        })
        .then((response) => {
          if (response.succeeded) {
            resolve(response.message);
          } else {
            reject(
              new Error(
                response.errors === undefined
                  ? "Failed to confirm email."
                  : response.errors[0].value
              )
            );
          }
        })
        .catch((error) => {
          reject(new Error("Failed to confirm email."));
        });
    });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };
  getUserRoleUrl
  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    this.emit("onLogout", "Logged out");
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };

  getUserRole = (emailAddress) => {
    return new Promise((resolve, reject) => {
        axios.post(jwtServiceConfig.getUserRoleUrl + `/${emailAddress}`).then((response) => {        
            if (response) {
                resolve(response);
            }
        });
    });
};

impersonateUser = (userId, user) => {
  const userModel = this._getUserModel();
  const { data } = userModel;
  localStorage.setItem("parentUserEmail", user?.data.email);

  return new Promise((resolve, reject) => {
    axios
      .post(jwtServiceConfig.impersonateUserUrl, {
        userId: userId
      })
      .then((response) => {
        if (response.succeeded) {
          this.setSession(response.result.authData.token);
          const roles = response.result.roles.map((x) => x.toLowerCase());
          const user = {
            ...userModel,
            data: { ...response.result.user, data },
            role: roles,
            provider: response.result.mappedEntities,
            isImpersonated: localStorage.getItem("parentUserEmail") ? true : false
          };
          resolve(user);
          if (!roles.includes("admin")) {
            window.location.href = "/landingPortal/Overview";
        }else{
          window.location.reload(false);
        }
         // window.location.reload(false);
        } else {
          reject(new Error("Failed to impersonate user."));
        }
      })
      .catch((error) => {
        reject(new Error("Failed to impersonate user."));
      });
  });
};

goBackFromImpersonation = () => {
  const userModel = this._getUserModel();
  const { data } = userModel;
  return new Promise((resolve, reject) => {
    axios
      .post(jwtServiceConfig.backFromImpersonationUserUrl, {
        userEmail: localStorage.getItem("parentUserEmail")
      })
      .then((response) => {
        if (response.succeeded) {
          localStorage.removeItem("parentUserEmail");
          this.setSession(response.result.authData.token);
          const roles = response.result.roles.map((x) => x.toLowerCase());
          const user = {
            ...userModel,
            data: { ...response.result.user, data },
            role: roles,
            provider: response.result.mappedEntities,
            isImpersonated: response.result.isImpersonated
          };
          resolve(user);

          window.location.href = "/admin/users";
        } else {
          reject(new Error("Failed to back from impersonation."));
        }
      })
      .catch((error) => {
        reject(new Error("Failed to back from impersonation."));
      });
  });
};

}

const instance = new JwtService();

export default instance;
