import { lazy } from "react";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";

const OverviewPage = lazy(() => import("./pages/overview/OverviewPage"));
const DetailsPage = lazy(() => import("./pages/details/DetailsPage"));
const CustomersPage = lazy(() => import("../crm/customers/pages/customers/CustomersPage"));

const RecruitingConfig = {
  settings: {
    layout: {},
  },
  //auth: settingsConfig.defaultAuth,//['admin']
  routes: [
    {
      path: "recruiting/overview",
      element: <OverviewPage />,
    },
    {
      path: "recruiting/details/:recruitmentRequestId",
      element: <DetailsPage />,
    },   
    {
      path: "crm/customers?recruitmentRequestId",
      element: <CustomersPage />,
    },     

  ],
};

export default RecruitingConfig;
