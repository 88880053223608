import { lazy } from "react";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import { authRoles } from "src/app/auth";

const CustomersPage = lazy(() => import("./pages/customers/CustomersPage"));
const CustomerPage = lazy(() => import("./pages/customer/CustomerPage"));
const ProvidersImportPage = lazy(() => import("./pages/customersImport/ProvidersImportPage"));
const ExpirationReviewPage = lazy(() => import("./pages/expirationReview/expirationReviewPage"));
const DetailsPage = lazy(() => import("../../recruiting/pages/details/DetailsPage"));
const OverviewPage = lazy(() => import("../../recruiting/pages/overview/OverviewPage"));
const Error404 = lazy(() => import("../../../404/Error404Page"));
const CRMConfig = {
 settings: {
    layout: {},
  },
 // auth: settingsConfig.defaultAuth,//['admin']
  routes: [ 
    {
      path: "crm/customers",
      element: <CustomersPage />,
    //  auth: [...authRoles.billing,...authRoles.recruiting ,...authRoles.customer]
      
    },
    {
      path: "crm/customer/:customerId",
      element: <CustomerPage />,
    //  auth: [...authRoles.billing,...authRoles.recruiting ,...authRoles.customer]
    },

    {
      path: "crm",
      element: <Navigate to="customers" />, 
    //  auth: [...authRoles.billing,...authRoles.recruiting ,...authRoles.customer]
    },
    {
      path: "crm/customersImport",
      element: <ProvidersImportPage />,
     // auth: authRoles.admin    
    },
    {
      path: "crm/expirationReview",
      element: <ExpirationReviewPage />,
     // auth: authRoles.admin    
    },    
    {
      path: "recruiting/details/:recruitmentRequestId?providermodel=true",
      element: <DetailsPage />,      
    }, 
    {
      path: "recruiting/overview",
      element: <OverviewPage />,     
    }, 
    {
      path: "error/404",
      element: <Error404 />,     
    }, 
  ],
};

export default CRMConfig;
