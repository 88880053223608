import FuseNavigation from "@fuse/core/FuseNavigation";
import clsx from "clsx";
import { memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNavigation,
  appendNavigationItem,
  removeNavigationItem,
  updateNavigationItem
} from "app/store/fuse/navigationSlice";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { navbarCloseMobile } from "app/store/fuse/navbarSlice";
import { selectUser } from "app/store/userSlice";
import authRoles from "../../auth/authRoles";


function Navigation(props) {
  const navigation = useSelector(selectNavigation);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const providers = user.provider;

  useEffect(() => {
    {
      providers &&
        providers.map((x) => {        
          dispatch(
            appendNavigationItem(
              {
                id: x.id,
                title: x.description,
                type: "item",
                url: `crm/customer/${x.id}`,
                end: true,             
              },
              "customerPortal"
            )
          );
        });
    }
  }, [providers]);

  useEffect(() => {
    const hasOnlyCustomerAndGuestRoles =
    user.role.length === 2 &&
    user.role.includes("customer") &&
    user.role.includes("guest");

      if(hasOnlyCustomerAndGuestRoles){
        dispatch(removeNavigationItem("ProvidersImport"));

        if(providers.length <= 1 && providers.length != 0){
          dispatch(removeNavigationItem("Customers"));
          dispatch(
            updateNavigationItem(
              "CRM",
              {
                id: "CRM",
                title: "Providers",
                type: "item",
                url: `crm/customer/${providers[0].id}`,
                icon: "heroicons-outline:chart-bar",
                translate: "Providers",
               // auth:[...authRoles.billing,...authRoles.recruiting ,...authRoles.customer],
              }
            )
          );
          dispatch(removeNavigationItem("CRM"));
        }
    }
  }, [user]);

  return useMemo(() => {
    function handleItemClick(item) {
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
    }

    return (
      <FuseNavigation
        className={clsx("navigation", props.className)}
        navigation={navigation}
        layout={props.layout}
        dense={props.dense}
        active={props.active}
        onItemClick={handleItemClick}
      />
    );
  }, [
    dispatch,
    isMobile,
    navigation,
    props.active,
    props.className,
    props.dense,
    props.layout,
  ]);
}

Navigation.defaultProps = {
  layout: "vertical",
};

export default memo(Navigation);
