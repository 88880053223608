/**
 * Authorization Roles
 */
const authRoles = {
  admin: ["admin"],
  billing: ["admin", "billing"],
  recruiting: ["admin", "recruiting manager", "recruiter"],
  crm: ["guest"],
  anonymous: [],
  customer: ["customer"],
  
};

export default authRoles;
