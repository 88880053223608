import { lazy } from "react";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import CustomerPage from "./pages/Customer/CustomerPage";


const CustomerPortalConfig = {
  settings: {
    layout: {},
  },
  //auth: settingsConfig.defaultAuth,//['admin']
  routes: [    
    {
      path: "/customerPortal/customer",
      element: <CustomerPage/>,
    },
  ],
};

export default CustomerPortalConfig;
