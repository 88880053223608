import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {

  }
};

const customerPortalSlice = createSlice({
  name: "customerPortal/detail",
  initialState,
  reducers: {
    setFilters: (state = initialState, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setFilters } = customerPortalSlice.actions;
export const selectFilters = ({ customerPortal }) =>
customerPortal.filters;
export default customerPortalSlice.reducer;
