import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { selectUser } from "app/store/userSlice";
import JwtService from "src/app/auth/services/jwtService";
import { openDialog } from "app/store/fuse/dialogSlice";
import ConfirmationDialog from "app/shared-components/ConfirmationDialog";
function UserMenu(props) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [userMenu, setUserMenu] = useState(null);
  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };
  const userMenuClose = () => {
    setUserMenu(null);
  };
  const goBackFromImpersonationUserHandler = (id) => {
    dispatch(
      openDialog({
        children: (
          <ConfirmationDialog
            onAccept={() => {
              JwtService
                .goBackFromImpersonation()
                .then((message) => {
                  // refreshUsersTableData();
                })
                .catch((errorResult) => {
                  toastErrorMessage(errorResult.message);
                });
            }}
            title="Confirmation"
            message="You want to stop impersonating and return to your main profile. Are you sure ?"
          />
        ),
      })
    );
  };
  return (
    <>
         {
            user.isImpersonated &&
            (
              <Button
                variant="outlined"
                color="primary"
                onClick={goBackFromImpersonationUserHandler}
                style={{ marginRight: 10 }}
              >
                {"Exit Impersonation"}
              </Button>
            )
          }
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {user.data.displayName}
          </Typography>
          <Typography className="text-11 font-medium capitalize" color="text.secondary">
            {user.role && user.role.length > 0
              ? user.role.filter(role => role !== 'guest').join(', ')
              : "Guest"}
          </Typography>
        </div>

        {user.data.photoURL ? (
          <Avatar
            className="md:mx-4"
            alt="user photo"
            src={user.data.photoURL}
          />
        ) : (
          <Avatar className="md:mx-4">{user.data.displayName[0]}</Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem component={Link} to="/sign-in" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:lock-closed</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </MenuItem>
            <MenuItem component={Link} to="/sign-up" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-add </FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign up" />
            </MenuItem>
          </>
        ) : (
          <>
       
            <MenuItem
              component={Link}
              to="/account/overview"
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="My Account" />
            </MenuItem>

            <MenuItem
              component={NavLink}
              to="/sign-out"
              onClick={() => {
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
