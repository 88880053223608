import { lazy } from "react";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";

const DashboardPage = lazy(() => import("./pages/dashboard/DashboardPage"));
const OverviewPage = lazy(() => import("./pages/overview/OverviewPage"));
const ImportPage = lazy(() => import("./pages/import/ImportPage"));
const QtcPage = lazy(() => import("./pages/qtc/ImportPageQTC"));
const QtcOverviewPage = lazy(() => import("./pages/qtcOverview/OverviewPageQTC"));
const PartnersPage = lazy(() => import("./pages/partners/PartnersPage"));
const OverviewPageById = lazy(() => import("./pages/overview/OverviewPage"));

const BillingConfig = {
  settings: {
    layout: {},
  },
  //auth: settingsConfig.defaultAuth,//['admin']
  routes: [
    {
      path: "billing/dashboard",
      element: <DashboardPage />,
    },
    {
      path: "billing/overview",
      element: <OverviewPage />,
    },
    {
      path: "billing/import/",
      element: <ImportPage />,
    },
    {
      path: "billing/qtc/",
      element: <QtcPage />,
    },
    {
      path: "billing/qtcOverview/",
      element: <QtcOverviewPage />,
    }, 
    {
      path: "billing/partners/",
      element: <PartnersPage />,
    },        
    {
      path: "dashboard",
      element: <Navigate to="dashboard" />,
    },
    {
      path: "/billing/overview/:id/:description",
      element: <OverviewPageById/>,
    },
  ],
};

export default BillingConfig;
