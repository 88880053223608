import AppBar from '@mui/material/AppBar';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import Stack from '@mui/material/Stack';
import userMngServiceConfig from 'src/app/main/apps/admin/users-management/services/userMngService';

function FooterLayout1(props) {
  const footerTheme = useSelector(selectFooterTheme);
 const [versionNumber, setVersionNumber] = useState("0.0.0.0");

 useEffect(() => { 
  const fetchVersionNumber = async () => {   
    let version = await userMngServiceConfig.fetchVersionNumber();
    setVersionNumber(version);
  };
  fetchVersionNumber()
    .catch((e) => {     
    })
    .finally(() => {      
    });
}, []);


  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className={clsx('relative z-20 shadow-md', props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? footerTheme.palette.background.paper
              : footerTheme.palette.background.default,
        }}
      >
        <Toolbar className="min-h-48 md:min-h-28 px-8 sm:px-12 py-0 flex ">
          <Stack container   direction={{ xs: 'column', sm: 'row' }}  justifyContent="space-between"  alignItems="center" sx={{px:1, flex:1}}>
            <div>
                &copy; {new Date().getFullYear()} GROW - Gain, Recruit, Observe, Work. All rights reserved.
            </div>
            <div>Version number: {versionNumber}</div>
          </Stack>
          
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(FooterLayout1);
